export const ACTIVITY = 'ACTIVITY';
export const CHRONOLOGIC = 'CHRONOLOGIC';

export const HOMEFEED_MODE_FRIENDS_ONLY = 'friends-only';
export const HOMEFEED_MODE_CLASSIC = 'classic';
export const HOMEFEED_MODE_FRIENDS_ALL_ACTIVITY = 'friends-all-activity';

export const PREVIEW_ANIMATION_NONE = 'none';
export const PREVIEW_ANIMATION_HOVER = 'hover';
export const PREVIEW_ANIMATION_ALWAYS = 'always';
